import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createTheme, ThemeProvider } from '@material-ui/core'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const darkTheme = createTheme({
  palette: {
    primary: {
      main: '#556cd6'
    }
  }
})

const lightTheme = createTheme({
  palette: {
    primary: {
      main: '#ff0000'
    }
  }
})

const mainTheme = localStorage.getItem('palletType') !== 'ligth' ? darkTheme : lightTheme

root.render(
  <React.StrictMode>
    <ThemeProvider theme={mainTheme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
